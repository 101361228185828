import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import ContentWrapper from "styles/contentWrapper"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/category/hero"
import Clients from "components/sections/clients"
import Demos from "components/sections/category/demos"
import PrimaryCard from "components/common/primaryCard"
import Card from "components/common/card"
import Grid from "components/sections/grid"
import docImg from "content/images/search.svg"
import peopleImg from "content/images/people.svg"

import fileImg from "content/images/file.svg"
import CardNewsLetter from "components/common/cardNewsletter"
import Breadcumbs from "components/common/breadcumbs"

const documentLinking = "https://hrflow-ai.imgix.net/labs/document-linking.png?w=350&h=350&fit=clip"
const jobRevealingImg = "https://hrflow-ai.imgix.net/labs/job-imaging.webp?w=350&h=350&fit=clip"
const jobParsingImg = "https://hrflow-ai.imgix.net/labs/job-parsing.png?w=350&h=350&fit=clip"
const textImagingImg = "https://hrflow-ai.imgix.net/labs/job-revealing.png?w=350&h=350&fit=clip"

const StyledFlex = styled.div`
  min-height: 19rem;
  display: flex;
  margin: auto;
  margin-top: 2rem;
  flex-wrap: wrap;
`
const DocumentIndexPage = ({ data }) => {
  const { frontmatter } = data.index.edges[0].node
  const { seoTitle, useSeoTitleSuffix, useSplashScreen } = frontmatter

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }


  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          frontmatter={frontmatter}
        />
        <Breadcumbs
          color="#9F7AEA"
          text="Back to demos"
          link="/"
        />
        <Hero
          content={data.hero.edges}
          logo={docImg}
          color="#9F7AEA"
          docs="https://developers.hrflow.ai/reference/text-parsing-1"
        />
        <Grid>
          <PrimaryCard
            image={jobParsingImg}
            color="#9F7AEA"
            title="Text Parsing"
            link="/text-analysis/parsing/"
            subtitle="Enter an unstructured text and extract its semantic entities — such as companies, locations, tasks, skills, interests, durations, dates and more."
          />
          <PrimaryCard
            image={jobRevealingImg}
            color="#9F7AEA"
            title="Text Tagging"
            link="/text-analysis/tagging/"
            subtitle="Predict most likely tags for a text with our library of AI algorithms."
          />
          <PrimaryCard
            image={documentLinking}
            color="#9F7AEA"
            title="Text Linking"
            link="/text-analysis/linking/"
            subtitle="Enter a word and get its Top 10 closest words whether it is a person name, school, organization, skill, interest, country and more."
          />
          <PrimaryCard
            image={textImagingImg}
            color="#9F7AEA"
            title="Text Imaging"
            link="/text-analysis/imaging/"
            subtitle="Enter a job description to generate an image that showcases the tasks required from your future employees."
          />
          {/* <PrimaryCard
            image={jobRevealingImg}
            color="#9F7AEA"
            title="Text Revealing"
            link="/text-analysis/revealing/"
            subtitle="Enter an unstructured text and predict the forgotten soft and hard skills."
          /> */}
          <CardNewsLetter 
            background="linear-gradient(284.6deg, #7151D0 0%, #B498F4 114.62%)"
            button="linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #A98CEF"
          />
        </Grid>
        <Clients content={data.clients.edges} category />
        <Demos content={data.demos.edges}>
          <StyledFlex>
            <Card
              color="#22B5F3"
              icon={peopleImg}
              description="Dive in to interactive demos that derive insights from profiles, resumes and CVs."
              title="Profile Analysis"
              link="/profile-analysis/"
            />
            <Card
              color="#2A7CF8"
              icon={fileImg}
              description="Dive in to interactive demos that derive insights from job offers."
              title="Job Analysis"
              link="/job-analysis/"
            />
          </StyledFlex>
        </Demos>
      </Layout>
    </GlobalStateProvider>
  )
}

DocumentIndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DocumentIndexPage

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/text/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            seoImage
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/text/index/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/home/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, quality: 90, placeholder: NONE)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    demos: allMdx(filter: { fileAbsolutePath: { regex: "/text/index/demos/" } }) {
      edges {
        node {
          frontmatter {
            label
            title
            titlePrefix
            description
          }
        }
      }
    }
  }
`
